import _isHexPrefixed from "is-hex-prefixed";
var exports = {};
var isHexPrefixed = _isHexPrefixed;
/**
 * Removes '0x' from a given `String` is present
 * @param {String} str the string value
 * @return {String|Optional} a string by pass if necessary
 */

exports = function stripHexPrefix(str) {
  if (typeof str !== "string") {
    return str;
  }

  return isHexPrefixed(str) ? str.slice(2) : str;
};

export default exports;